<template>
  <!--begin::Wrapper-->
  <div class="bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
    <!--begin::Form-->
    <Form
      class="form"
      id="apply_form"
      @submit="onSubmitApplication"
      :validation-schema="validation"
    >
      <!--begin::Heading-->
      <div class="text-center mb-10">
        <h1 class="text-dark mb-3">Employment Application</h1>
      </div>
      <div class="mb-10">
        <p style="font-style: italic">
          <span style="font-weight: bold">NOTE:</span> This application is valid
          for 30 days. If you wish to be considered for employment after this
          30-day period, a new application must be completed.
        </p>
        <p style="font-style: italic">
          It is the policy of Snappy Popcorn, CO. not to discriminate in hiring
          and employment on the basis of race, color, religion, national origin,
          sex, disability, age or other protected class as provided by all
          applicable state and federal laws. It is our intention that all
          qualified applicants be given equal opportunity and that selection
          decisions be based on job-related factors.
        </p>
        <p style="font-style: italic">
          Answer each question fully and accurately. If you provide false or
          misleading information during the application process or during your
          employment with Snappy Popcorn, CO. this may result in disciplinary
          action including up to termination of employment or a decision to not
          consider you further for employment. PLEASE PRINT, except for
          signature on back of application. In reading and answering the
          following questions, be aware that none of the questions are intended
          to imply illegal preferences or discrimination based upon
          non-job-related information.
        </p>
      </div>
      <!--begin::Heading-->

      <!--begin:: Personal Information -->
      <div class="d-flex flex-column mb-10">
        <h3 class="bg-secondary p-3">Personal Information</h3>
        <div class="d-flex flex-column">
          <div class="d-flex flex-row flex-wrap">
            <!--begin:: First Name Input -->
            <div id="firstName" class="m-3 flex-fill">
              <label class="form-label fs-6 fw-bolder text-dark required"
                >First Name</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                name="firstName"
                autocomplete="off"
                v-model="employmentApplication.firstName"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="firstName" />
                </div>
              </div>
            </div>
            <!--end:: First Name Input -->
            <!--begin:: Last Name Input -->
            <div id="lastName" class="m-3 flex-fill">
              <label class="form-label fs-6 fw-bolder text-dark required"
                >Last Name</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                name="lastName"
                autocomplete="off"
                v-model="employmentApplication.lastName"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="lastName" />
                </div>
              </div>
            </div>
            <!--end:: First Name Input -->
            <!--begin:: Email Input -->
            <div id="email" class="m-3 flex-fill">
              <label class="form-label fs-6 fw-bolder text-dark">Email</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                name="email"
                autocomplete="off"
                v-model="employmentApplication.applicantEmail"
              />
            </div>
            <!--end:: Email Input -->
            <!--begin:: Phone Input -->
            <div id="phone" class="m-3 flex-fill">
              <label class="form-label fs-6 fw-bolder text-dark required"
                >Phone</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                name="phone"
                autocomplete="off"
                placeholder="xxx-xxx-xxxx"
                v-model="employmentApplication.phoneNumber"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="phone" />
                </div>
              </div>
            </div>
            <!--end:: Phone Input -->
          </div>
          <div class="separator my-2"></div>
          <!--begin:: Present Address -->
          <div class="d-flex">
            <label class="form-label fs-6 mx-3 mb-0 snappy-primary-blue"
              >Present Address</label
            >
          </div>
          <div class="d-flex flex-row flex-wrap">
            <!--begin:: Present Street -->
            <div id="presentStreet" class="m-3 flex-fill">
              <label class="form-label fs-6 fw-bolder text-dark">Street</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                name="presentStreet"
                autocomplete="off"
                v-model="employmentApplication.presentStreetAddress"
              />
            </div>
            <!--end:: Present Street -->
            <div class="d-flex flex-row flex-fill">
              <!--begin:: Present City -->
              <div id="presentCity" class="m-3 flex-fill">
                <label class="form-label fs-6 fw-bolder text-dark">City</label>
                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  name="presentCity"
                  autocomplete="off"
                  v-model="employmentApplication.presentCity"
                />
              </div>
              <!--end:: Present City -->
            </div>
            <div class="d-flex flex-row w-sm-100">
              <!--begin:: Present State -->
              <div id="presentState" class="m-3 flex-fill w-sm-50">
                <label class="form-label fs-6 fw-bolder text-dark">State</label>
                <Field
                  name="state"
                  class="form-select form-select-solid"
                  as="select"
                  v-model="employmentApplication.presentState"
                >
                  <option value="">Select a State...</option>
                  <option
                    v-for="item in usStates"
                    :key="item"
                    :label="item.stateName"
                    :value="item.stateAbbrev"
                  ></option>
                </Field>
              </div>
              <!--end:: Present State -->
              <!--begin:: Present Zip Code -->
              <div id="presentZipCode" class="m-3 flex-fill w-25 w-sm-50">
                <label class="form-label fs-6 fw-bolder text-dark"
                  >Zip Code</label
                >
                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  name="presentZipCode"
                  autocomplete="off"
                  v-model="employmentApplication.presentZipCode"
                />
              </div>
              <!--end:: Present Zip Code -->
            </div>
          </div>
          <!--end:: Present Address -->
          <div class="separator my-2"></div>
          <!--begin:: Mailing Address -->
          <div class="d-flex">
            <label class="form-label fs-6 mx-3 mb-0 snappy-primary-blue"
              >Mailing Address (if different that present address)</label
            >
          </div>
          <div class="d-flex flex-row flex-wrap">
            <!--begin:: Mailing Street -->
            <div id="mailingStreet" class="m-3 flex-fill">
              <label class="form-label fs-6 fw-bolder text-dark">Street</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                name="mailingStreet"
                autocomplete="off"
                v-model="employmentApplication.mailingStreetAddress"
              />
            </div>
            <!--end:: Mailing Street -->
            <div class="d-flex flex-row flex-fill">
              <!--begin:: Mailing City -->
              <div id="mailingCity" class="m-3 flex-fill">
                <label class="form-label fs-6 fw-bolder text-dark">City</label>
                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  name="mailingCity"
                  autocomplete="off"
                  v-model="employmentApplication.mailingCity"
                />
              </div>
              <!--end:: Mailing City -->
            </div>
            <div class="d-flex flex-row w-sm-100">
              <!--begin:: Mailing State -->
              <div id="mailingState" class="m-3 flex-fill w-sm-50">
                <label class="form-label fs-6 fw-bolder text-dark">State</label>
                <Field
                  name="mailingstate"
                  class="form-select form-select-solid"
                  as="select"
                  v-model="employmentApplication.mailingState"
                >
                  <option value="">Select a State...</option>
                  <option
                    v-for="item in usStates"
                    :key="item"
                    :label="item.stateName"
                    :value="item.stateAbbrev"
                  ></option>
                </Field>
              </div>
              <!--end:: Mailing State -->
              <!--begin:: Mailing Zip Code -->
              <div id="mailingZipCode" class="m-3 flex-fill w-25 w-sm-50">
                <label class="form-label fs-6 fw-bolder text-dark"
                  >Zip Code</label
                >
                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  name="mailingZipCode"
                  autocomplete="off"
                  v-model="employmentApplication.mailingZipCode"
                />
              </div>
              <!--end:: Mailing Zip Code -->
            </div>
          </div>
          <!--end:: Mailing Address -->
          <div class="separator my-2"></div>
          <!--begin::Emergency Info -->
          <div class="d-flex">
            <label class="form-label fs-6 mx-3 mb-0 snappy-primary-blue"
              >In case of an emergency please notify:</label
            >
          </div>
          <div class="d-flex flex-row flex-wrap">
            <!--begin:: Emergency Contact -->
            <div id="emergencyContactName" class="m-3 flex-fill">
              <label class="form-label fs-6 fw-bolder text-dark"
                >Emergency Contact Name</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                name="emergencyContactName"
                autocomplete="off"
                v-model="employmentApplication.emergencyContactName"
              />
            </div>
            <!--end:: Emergency Contact -->
            <div class="d-flex flex-row flex-fill">
              <!--begin:: Emergency Relationship -->
              <div id="emergencyRelationship" class="m-3 flex-fill">
                <label class="form-label fs-6 fw-bolder text-dark"
                  >Relationship</label
                >
                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  name="emergencyRelationship"
                  autocomplete="off"
                  v-model="employmentApplication.emergencyContactRelationship"
                />
              </div>
              <!--end:: Emergency Relationship -->
            </div>
            <!--begin:: Emergecy Contact Phone -->
            <div id="emergencyPhoneNumber" class="m-3 flex-fill">
              <label class="form-label fs-6 fw-bolder text-dark"
                >Emergency Contact Phone</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                name="emergencyPhoneNumber"
                autocomplete="off"
                v-model="employmentApplication.emergencyContactPhone"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="emergencyPhoneNumber" />
                </div>
              </div>
            </div>
            <!--end:: Emergency Contact Phone -->
          </div>
          <!--end::Emergency Info -->
          <div class="separator my-2"></div>
          <!--begin::Eligible for Employment -->
          <div
            id="eligibleEmployment"
            class="d-flex flex-stack w-md-75 w-lg-60"
          >
            <!--begin::Label-->
            <div class="me-5">
              <label class="fs-6 fw-bolder text-dark"
                >Are you legally eligible for employment in the U.S.?</label
              >
              <div class="fs-7 fw-semobold text-gray-600">
                (Proof of eligibility will be required)
              </div>
            </div>
            <!--end::Label-->
            <!--begin::Switch-->
            <label
              class="form-check form-switch form-check-custom form-check-solid"
            >
              <Field
                class="form-check-input"
                name="eligibleEmployment"
                type="checkbox"
                value="true"
                checked="checked"
                v-model="employmentApplication.isEligibleForEmployment"
              />
              <!--begin::Label-->
              <span class="form-check-label fw-semobold text-gray-800">
                Yes
              </span>
              <!--end::Label-->
            </label>
            <!--end::Switch-->
          </div>
          <!--end::Eligible for Employment-->
          <div class="separator my-2"></div>
          <!--begin::Least 18 -->
          <div id="leastEighteen" class="d-flex flex-stack w-md-75 w-lg-60">
            <!--begin::Label-->
            <div class="me-5">
              <label class="fs-6 fw-bolder text-dark"
                >Are you at least 18 years of age?</label
              >
              <div class="fs-7 fw-semobold text-gray-600">
                (Proof of eligibility will be required)
              </div>
            </div>
            <!--end::Label-->
            <!--begin::Switch-->
            <label
              class="form-check form-switch form-check-custom form-check-solid"
            >
              <Field
                class="form-check-input"
                name="leastEighteen"
                type="checkbox"
                value="true"
                checked="checked"
                v-model="employmentApplication.isEighteen"
              />
              <!--begin::Label-->
              <span class="form-check-label fw-semobold text-gray-800">
                Yes
              </span>
              <!--end::Label-->
            </label>
            <!--end::Switch-->
          </div>
          <!--end::Least 18-->
          <div class="separator my-2"></div>
          <!--begin::Read, Write, Speak English -->
          <div id="leastEighteen" class="d-flex flex-stack w-md-75 w-lg-60">
            <!--begin::Label-->
            <div class="me-5">
              <label class="fs-6 fw-bolder text-dark"
                >Can you read, write, and speak fluently in English?</label
              >
            </div>
            <!--end::Label-->
            <!--begin::Switch-->
            <label
              class="form-check form-switch form-check-custom form-check-solid"
            >
              <Field
                class="form-check-input"
                name="fluentEnglish"
                type="checkbox"
                value="true"
                checked="checked"
                v-model="employmentApplication.fluentEnglish"
              />
              <!--begin::Label-->
              <span class="form-check-label fw-semobold text-gray-800">
                Yes
              </span>
              <!--end::Label-->
            </label>
            <!--end::Switch-->
          </div>
          <!--end::Read, Write, Speak English-->
          <div class="separator my-2"></div>
          <!--begin::Convictions -->
          <div id="convictions" class="d-flex flex-stack">
            <!--begin::Label-->
            <div class="me-5">
              <label class="fs-6 fw-bolder text-dark"
                >Have you ever plead guilty to; or been convicted of a crime
                other than minor traffic violations such as parking
                tickets…etc.?</label
              >
              <div class="fs-6 fw-semobold text-gray-600">
                <small
                  >(A conviction will not automatically disqualify you for
                  employment. Rather, such factors as conviction, seriousness
                  and nature of the crime, and rehabilitation will be
                  considered).</small
                >
              </div>
            </div>
            <!--end::Label-->
            <!--begin::Switch-->
            <label
              class="form-check form-switch form-check-custom form-check-solid"
            >
              <Field
                class="form-check-input"
                name="convictions"
                type="checkbox"
                value="true"
                checked="checked"
                v-model="employmentApplication.beenConvicted"
              />
              <!--begin::Label-->
              <span class="form-check-label fw-semobold text-gray-800">
                Yes
              </span>
              <!--end::Label-->
            </label>
            <!--end::Switch-->
          </div>
          <div id="convictionExplanation" class="d-flex flex-column">
            <label class="d-flex align-items-center fs-6 fw-semobold mb-2">
              <span>(If yes, please explain)</span>
            </label>
            <el-input
              v-model="employmentApplication.convictionExplanation"
              type="textarea"
              rows="2"
              name="convictionExplanation"
              @keydown="convictionExplanationKeyDown"
            ></el-input>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <p class="text-danger bold">
                  Limit 200 Characters ({{
                    200 - employmentApplication.convictionExplanation.length
                  }})
                </p>
              </div>
            </div>
          </div>

          <!--end::Convictions-->
        </div>
      </div>
      <!--end:: Personal Information -->

      <!--begin:: Employment Desired Information -->
      <div class="d-flex flex-column mb-10">
        <h3 class="bg-secondary p-3">Employment Desired</h3>
        <div class="d-flex flex-column">
          <div class="d-flex flex-row flex-wrap">
            <!--begin:: Position Desired -->
            <div id="positionDesired" class="m-3 flex-fill">
              <label class="form-label fs-6 fw-bolder text-dark required"
                >Position Desired</label
              >
              <Field
                name="positionDesired"
                class="form-select form-select-solid"
                as="select"
                v-model="employmentApplication.positionDesired"
              >
                <option value="">Select a Position...</option>
                <option value="Production Packager">Production Packager</option>
                <option value="Truck Driver">Truck Driver</option>
              </Field>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="positionDesired" />
                </div>
              </div>
            </div>
            <!--end:: Position Desired -->
            <!--begin::Employment Time -->
            <div
              id="positionDesired"
              class="
                d-flex
                flex-row flex-fill
                align-items-end
                justify-content-around
                mb-4
              "
            >
              <!--begin::Switch-->
              <label
                class="
                  form-check form-switch form-check-custom form-check-solid
                "
              >
                <Field
                  class="form-check-input"
                  name="fullTime"
                  type="checkbox"
                  value="true"
                  checked="checked"
                  v-model="employmentApplication.fullTime"
                />
                <!--begin::Label-->
                <span class="form-check-label fw-semobold text-gray-800">
                  Full Time
                </span>
                <!--end::Label-->
              </label>
              <!--end::Switch-->
              <!--begin::Switch-->
              <label
                class="
                  form-check form-switch form-check-custom form-check-solid
                "
              >
                <Field
                  class="form-check-input"
                  name="partTime"
                  type="checkbox"
                  value="true"
                  checked="checked"
                  v-model="employmentApplication.partTime"
                />
                <!--begin::Label-->
                <span class="form-check-label fw-semobold text-gray-800">
                  Part Time
                </span>
                <!--end::Label-->
              </label>
              <!--end::Switch-->
            </div>
            <!--end::Employment Time -->
            <!--begin::Salary Requirements -->
            <div id="salaryRequirements" class="m-3 flex-fill">
              <label class="form-label fs-6 fw-bolder text-dark"
                >Salary Requirement</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                name="salaryRequirements"
                autocomplete="off"
                v-model="employmentApplication.salaryRequirements"
              />
            </div>
            <!--end::Salary Requirements -->
          </div>
          <div class="separator my-2"></div>
          <div class="d-flex flex-row flex-wrap align-items-center">
            <!--begin:: Available to Start -->
            <div id="availableStart" class="d-flex flex-row m-3 w-25-md">
              <label class="form-label fs-6 fw-bolder text-dark mt-2"
                >When are you available to start?</label
              >
              <el-date-picker
                name="availableStart"
                placeholder="Start date"
                value-format="YYYY-MM-DD"
                class="mx-4 p-0"
                v-model="employmentApplication.availableToStart"
              />
            </div>
            <!--end:: Available to Start -->
          </div>
          <div class="separator my-2"></div>
          <div class="d-flex flex-row flex-wrap">
            <!--begin:: Work Overtime -->
            <div
              id="workOvertime"
              class="d-flex flex-row flex-fill m-3 pt-lg-5 w-lg-45"
            >
              <!--begin::Switch-->
              <label
                class="
                  form-check form-switch form-check-custom form-check-solid
                  fs-6
                  fw-bolder
                  text-dark
                "
                >If needed, are you available to work overtime?
                <Field
                  class="form-check-input mx-2"
                  name="workOvertime"
                  type="checkbox"
                  value="true"
                  checked="checked"
                  v-model="employmentApplication.overtime"
                />
                <!--begin::Label-->
                <span class="form-check-label fw-semobold text-gray-800">
                  Yes
                </span>
                <!--end::Label-->
              </label>
              <!--end::Switch-->
            </div>
            <!--end:: Work Overtime -->
            <!--begin::Preferred Hours -->
            <div id="preferredHours" class="flex-fill m-3">
              <label class="form-label fs-6 fw-bolder text-dark"
                >What hours do you prefer?</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                name="preferredHours"
                autocomplete="off"
                v-model="employmentApplication.preferredHours"
              />
            </div>
            <!--end::Preferred Hours -->
          </div>
          <div class="separator my-2"></div>
          <div class="d-flex flex-row flex-wrap">
            <!--begin:: Employment -->
            <div
              id="currentlyEmployed"
              class="d-flex flex-row flex-fill m-3 pt-lg-5 w-lg-45"
            >
              <!--begin::Switch-->
              <label
                class="
                  form-check form-switch form-check-custom form-check-solid
                  fs-6
                  fw-bolder
                  text-dark
                "
                >Are you employed now?
                <Field
                  class="form-check-input mx-2"
                  name="currentlyEmployed"
                  type="checkbox"
                  value="true"
                  checked="checked"
                  v-model="employmentApplication.currentlyEmployed"
                />
                <!--begin::Label-->
                <span class="form-check-label fw-semobold text-gray-800">
                  Yes
                </span>
                <!--end::Label-->
              </label>
              <!--end::Switch-->
            </div>
            <!--end:: Employment -->
            <!--begin::Unemployed -->
            <div id="howLongUnemployed" class="flex-fill m-3">
              <label class="form-label fs-6 fw-bolder text-dark"
                >If unemployed, how long?</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                name="howLongUnemployed"
                autocomplete="off"
                v-model="employmentApplication.howLongUnemployed"
              />
            </div>
            <!--end::Unemployed -->
          </div>
          <div class="separator my-2"></div>
          <div class="d-flex flex-row flex-wrap">
            <!--begin:: Applied to Snappy -->
            <div
              id="appliedToSnappy"
              class="d-flex flex-row flex-fill m-3 pt-lg-5 w-lg-45"
            >
              <!--begin::Switch-->
              <label
                class="
                  form-check form-switch form-check-custom form-check-solid
                  fs-6
                  fw-bolder
                  text-dark
                "
                >Have you ever applied to Snappy Popcorn?
                <Field
                  class="form-check-input mx-2"
                  name="appliedToSnappy"
                  type="checkbox"
                  value="true"
                  checked="checked"
                  v-model="employmentApplication.appliedToSnappy"
                />
                <!--begin::Label-->
                <span class="form-check-label fw-semobold text-gray-800">
                  Yes
                </span>
                <!--end::Label-->
              </label>
              <!--end::Switch-->
            </div>
            <!--end:: Applied to Snappy -->
            <!--begin::Applied When To Snappy -->
            <div id="appliedWhenToSnappy" class="flex-fill m-3">
              <label class="form-label fs-6 fw-bolder text-dark"
                >If so, when?</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                name="appliedWhenToSnappy"
                autocomplete="off"
                v-model="employmentApplication.appliedWhenToSnappy"
              />
            </div>
            <!--end::Applied When To Snappy -->
          </div>
          <div class="separator my-2"></div>
          <div class="d-flex flex-row flex-wrap">
            <!--begin:: Snappy Referral -->
            <div id="snappyReferral" class="m-3 flex-fill">
              <label class="form-label fs-6 fw-bolder text-dark"
                >How were you referred to Snappy Popcorn?</label
              >
              <Field
                name="snappyReferral"
                class="form-select form-select-solid"
                as="select"
                v-model="employmentApplication.snappyReferral"
              >
                <option value="">Select a Referral...</option>
                <option value="Employment Agency">Employment Agency</option>
                <option value="Advertisement">Advertisement</option>
                <option value="School">School</option>
                <option value="Internal Posting">Internal Posting</option>
                <option value="Friend">Friend</option>
                <option value="Recruiter">Recruiter</option>
                <option value="Walk-in">Walk-in</option>
                <option value="Other">Other</option>
              </Field>
              <div id="otherReferal" class="mt-3">
                <label
                  v-if="showOtherReferral"
                  class="form-label fs-6 fw-bolder text-dark"
                  >Enter Other Referral</label
                >
                <Field
                  v-if="showOtherReferral"
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  name="otherSnappyReferral"
                  autocomplete="off"
                  v-model="employmentApplication.snappyReferralOther"
                />
              </div>
            </div>
            <!--end:: Snappy Referral -->
          </div>
        </div>
      </div>
      <!--end:: Employment Desired Information -->

      <!--begin:: Education -->
      <div class="d-flex flex-column mb-10">
        <h3 class="bg-secondary p-3">Education</h3>

        <!--begin:: Education Block -->
        <div
          class="d-flex flex-column"
          v-for="(input, index) in educations"
          :key="`educationBlock-${index}`"
        >
          <p
            class="fs-6 snappy-primary-blue fw-bold"
            style="margin-left: 10px; margin-bottom: 0px"
          >
            - Education {{ index + 1 }} -
          </p>
          <div class="d-flex justify-content-between flex-wrap">
            <div :id="'educationLevel-' + index" class="m-3 flex-fill">
              <label class="form-label fs-6 fw-bolder text-dark"
                >Education Level</label
              >
              <Field
                :name="'educationLevel-' + index"
                class="form-select form-select-solid"
                as="select"
                v-model="educations[index].educationLevel"
              >
                <option value="">Select Education Level...</option>
                <option value="High School">High School</option>
                <option value="Trade School">Trade School</option>
                <option value="Business School">Business School</option>
                <option value="Technical School">Technicial School</option>
                <option value="College">College</option>
                <option value="Graduate">Graduate</option>
              </Field>
            </div>
            <div :id="'schoolName-' + index" class="m-3 flex-fill">
              <label class="form-label fs-6 fw-bolder text-dark"
                >School Name</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                :name="'schoolName-' + index"
                autocomplete="off"
                placeholder="school name"
                v-model="educations[index].name"
              />
            </div>
            <div :id="'schoolLocation-' + index" class="m-3 flex-fill">
              <label class="form-label fs-6 fw-bolder text-dark"
                >Location</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                :name="'schoolLocation' + index"
                autocomplete="off"
                placeholder="city, state"
                v-model="educations[index].location"
              />
            </div>
          </div>
          <div class="d-flex justify-content-between flex-wrap">
            <div :id="'courseOfStudy-' + index" class="m-3 flex-fill">
              <label class="form-label fs-6 fw-bolder text-dark"
                >Course of Study</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                :name="'courseStudy-' + index"
                autocomplete="off"
                placeholder="course of study"
                v-model="educations[index].courseOfStudy"
              />
            </div>
            <div :id="'yearsCompleted-' + index" class="m-3 flex-fill">
              <label class="form-label fs-6 fw-bolder text-dark"
                ># of Years Completed</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                :name="'yearsCompleted-' + index"
                autocomplete="off"
                placeholder="years completed"
                v-model="educations[index].yearsCompleted"
              />
            </div>
            <div :id="'degreeDiploma-' + index" class="m-3 flex-fill">
              <label class="form-label fs-6 fw-bolder text-dark"
                >Degree/Diploma</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                :name="'degreeDiploma-' + index"
                autocomplete="off"
                placeholder="degree/diploma"
                v-model="educations[index].degreeDiploma"
              />
            </div>
          </div>
          <div class="separator my-2"></div>
        </div>
        <!--end:: Education Block -->
        <div class="d-flex m-3 justify-content-between">
          <!--begin::Add Education-->
          <button
            v-if="showAddEducationBtn"
            type="button"
            class="btn btn-primary"
            @click="addEducation()"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotune/arrows/arr075.svg" />
            </span>
            Enter Another Education Level
          </button>
          <div v-if="!showAddEducationBtn">
            <p class="text-danger bold">4 Education entries is the max</p>
          </div>
          <!--end::Add Education-->
          <!--begin::Remove Education-->
          <button
            v-if="showRemoveEducationBtn"
            type="button"
            class="btn btn-danger"
            @click="removeEducation()"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotune/arrows/arr010.svg" />
            </span>
            Remove Education Level
          </button>
          <!--end::Remove Education-->
        </div>
        <div class="mt-3">
          <label
            class="d-flex align-items-center fs-6 fw-bolder text-dark mb-2"
          >
            <span
              >Please include any other education, certification or skill which
              is relevant to the position for which you are applying: e.g.,
              additional education or course work, seminars or workshops, honors
              or activities, certifications, licensing, professional
              organizations, publications, other experiences or accomplishments
              (excluding labor organizations and memberships which reveal race,
              color, religion, national origin, sex, age, disability or other
              protected status).</span
            >
          </label>
          <el-input
            v-model="employmentApplication.otherEducation"
            type="textarea"
            rows="4"
            name="otherEducation"
            @keydown="otherEducationKeyDown"
          ></el-input>
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <p class="text-danger bold">
                Limit 430 Characters ({{
                  430 - employmentApplication.otherEducation.length
                }})
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--end:: Education -->

      <!--begin:: Military -->
      <div class="d-flex flex-column mb-10">
        <h3 class="bg-secondary p-3">Military</h3>
        <div class="d-flex flex-row flex-wrap">
          <!--begin:: Serve in Armed Forces -->
          <div id="servedArmedForce" class="d-flex flex-row flex-fill m-3">
            <!--begin::Switch-->
            <label
              class="
                form-check form-switch form-check-custom form-check-solid
                fs-6
                fw-bolder
                text-dark
              "
              >Did you serve in the U.S. Armed Forces?
              <Field
                class="form-check-input mx-2"
                name="servedArmedForce"
                type="checkbox"
                value="true"
                checked="checked"
                v-model="employmentApplication.servedArmedForces"
              />
              <!--begin::Label-->
              <span class="form-check-label fw-semobold text-gray-800">
                Yes
              </span>
              <!--end::Label-->
            </label>
            <!--end::Switch-->
          </div>
          <!--end:: Serve in Armed Forces -->
        </div>
        <!--begin:: National Guard or Reserves -->
        <div id="nationalGuardOrReserves" class="d-flex flex-row flex-fill m-3">
          <!--begin::Switch-->
          <label
            class="
              form-check form-switch form-check-custom form-check-solid
              fs-6
              fw-bolder
              text-dark
            "
            >Are you presently a member of the National Guard or Reserves?
            <Field
              class="form-check-input mx-2"
              name="nationalGuardOrReserves"
              type="checkbox"
              value="true"
              checked="checked"
              v-model="employmentApplication.nationalGuardOrReserves"
            />
            <!--begin::Label-->
            <span class="form-check-label fw-semobold text-gray-800">
              Yes
            </span>
            <!--end::Label-->
          </label>
          <!--end::Switch-->
        </div>
        <!--end:: National Guard or Reserves -->
        <!--begin:: Relevant Training -->
        <div id="relevantTraining" class="d-flex flex-column flex-fill m-3">
          <label
            class="d-flex align-items-center fs-6 fw-bolder text-dark mb-2"
          >
            <span
              >Please describe any training relevant to the position for which
              you are applying</span
            >
          </label>
          <el-input
            v-model="employmentApplication.relevantTraining"
            type="textarea"
            rows="2"
            name="relevantTraining"
            @keydown="relevantTrainingKeyDown"
          ></el-input>
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <p class="text-danger bold">
                Limit 210 Characters ({{
                  210 - employmentApplication.relevantTraining.length
                }})
              </p>
            </div>
          </div>
        </div>
        <!--end:: Relevant Training -->
      </div>
      <!--end:: Military -->

      <!--begin:: References -->
      <div class="d-flex flex-column mb-10">
        <h3 class="bg-secondary p-3">References</h3>
        <p class="fs-6 fw-bolder text-dark m-3">
          (Give names of three persons not related to you, whom you have known
          at least one year)
        </p>
        <div
          class="d-flex d-flex flex-row flex-wrap"
          v-for="(input, index) in references"
          :key="`referenceBlock-${index}`"
        >
          <!--begin:: Reference Block -->
          <p
            class="fs-6 snappy-primary-blue fw-bold"
            style="margin-left: 10px; margin-bottom: 0px"
          >
            Reference {{ index + 1 }}
          </p>
          <!--begin:: Reference One Name-->
          <div
            :id="'referenceName-' + index"
            class="d-flex flex-column flex-fill m-3"
          >
            <label class="form-label fs-6 fw-bolder text-dark">Name</label>
            <Field
              class="form-control form-control-lg form-control-solid"
              type="text"
              :name="'referenceName-' + index"
              autocomplete="off"
              placeholder="name"
              v-model="references[index].name"
            />
          </div>
          <!--end:: Reference One Name-->
          <!--end:: Reference One Relationship-->
          <div
            :id="'referenceRelationship-' + index"
            class="d-flex flex-column flex-fill m-3"
          >
            <label class="form-label fs-6 fw-bolder text-dark"
              >Relationship</label
            >
            <Field
              class="form-control form-control-lg form-control-solid"
              type="text"
              :name="'referenceRelationship-' + index"
              autocomplete="off"
              placeholder="relationship"
              v-model="references[index].relationship"
            />
          </div>
          <!--end:: Reference One Relationship-->
          <!--end:: Reference One Company-->
          <div
            :id="'referenceCompany-' + index"
            class="d-flex flex-column flex-fill m-3"
          >
            <label class="form-label fs-6 fw-bolder text-dark">Company</label>
            <Field
              class="form-control form-control-lg form-control-solid"
              type="text"
              :name="'referenceCompany-' + index"
              autocomplete="off"
              placeholder="company"
              v-model="references[index].company"
            />
          </div>
          <!--end:: Reference One Company-->
          <!--end:: Reference One phone-->
          <div
            :id="'referencePhone-' + index"
            class="d-flex flex-column flex-fill m-3"
          >
            <label class="form-label fs-6 fw-bolder text-dark"
              >Phone Number</label
            >
            <Field
              class="form-control form-control-lg form-control-solid"
              type="text"
              :name="'referencePhone-' + index"
              autocomplete="off"
              placeholder="phone number"
              v-model="references[index].phone"
            />
          </div>

          <div class="separator my-2"></div>
          <!--end:: Reference Block -->
        </div>
      </div>
      <!--end:: References -->

      <!--begin:: Former Employees -->
      <div class="d-flex flex-column mb-10">
        <h3 class="bg-secondary p-3">Former Employers</h3>
        <p class="fs-6 fw-bolder text-dark m-3">
          (List last four, starting with most recent)
        </p>
        <!--begin:: Former Employee Block -->
        <div
          class="d-flex flex-column mt-4"
          v-for="(input, index) in formerEmployers"
          :key="`employeeBlock-${index}`"
        >
          <p
            class="fs-6 snappy-primary-blue fw-bold"
            style="margin-left: 10px; margin-bottom: 0px"
          >
            Former Employer {{ index + 1 }}
          </p>
          <div class="d-flex flex-row flex-wrap">
            <!--begin:: Company Name-->
            <div
              :id="'formerEmployeeName-' + index"
              class="d-flex flex-column flex-fill m-3"
            >
              <label class="form-label fs-6 fw-bolder text-dark"
                >Company Name</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                :name="'formerEmployeeName-' + index"
                autocomplete="off"
                placeholder="company name"
                v-model="formerEmployers[index].companyName"
              />
            </div>
            <!--end:: Company Name-->
            <!--begin:: Dates of Employment-->
            <div
              :id="'employmentDates-' + index"
              class="d-flex flex-column flex-fill m-3"
            >
              <label class="form-label fs-6 fw-bolder text-dark m-0"
                >Dates of Employment</label
              >
              <div class="d-flex flex-row flex-fill">
                <div class="mt-2">
                  <label class="form-label fs-6 fw-bolder text-dark m-0"
                    >From:</label
                  >
                  <el-date-picker
                    :name="'fromDate-' + index"
                    placeholder="From date"
                    value-format="YYYY-MM-DD"
                    class="p-0 mx-md-3"
                    style="width: 155px; margin-left: 5px"
                    v-model="formerEmployers[index].fromDate"
                  />
                </div>
                <div class="mt-2 mx-2">
                  <label class="form-label fs-6 fw-bolder text-dark m-0"
                    >To:</label
                  >
                  <el-date-picker
                    :name="'toDate-' + index"
                    placeholder="To date"
                    value-format="YYYY-MM-DD"
                    class="p-0"
                    style="width: 145px; margin-left: 5px"
                    v-model="formerEmployers[index].toDate"
                  />
                </div>
              </div>
            </div>
            <!--end:: Dates of Employment-->
          </div>
          <!--begin:: Company Address-->
          <div
            :id="'companyAddress-' + index"
            class="d-flex flex-column flex-md-row flex-wrap"
          >
            <!--begin:: Address-->
            <div
              :id="'companyAddress-' + index"
              class="d-flex flex-column flex-fill m-3"
            >
              <label class="form-label fs-6 fw-bolder text-dark">Address</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                :name="'companyAddress-' + index"
                autocomplete="off"
                placeholder="address"
                v-model="formerEmployers[index].address"
              />
            </div>
            <!--end:: Address-->
            <!--end:: City-->
            <div
              :id="'companyCity-' + index"
              class="d-flex flex-column flex-fill m-3"
            >
              <label class="form-label fs-6 fw-bolder text-dark">City</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                :name="'companyCity-' + index"
                autocomplete="off"
                placeholder="city"
                v-model="formerEmployers[index].city"
              />
            </div>
            <!--end:: City-->
            <!--end:: State-->
            <div
              :id="'companyState-' + index"
              class="d-flex flex-column flex-fill m-3"
            >
              <label class="form-label fs-6 fw-bolder text-dark">State</label>
              <Field
                :name="'companyState-' + index"
                class="form-select form-select-solid"
                as="select"
                v-model="formerEmployers[index].state"
              >
                <option value="">Select a State...</option>
                <option
                  v-for="item in usStates"
                  :key="item"
                  :label="item.stateName"
                  :value="item.stateAbbrev"
                ></option>
              </Field>
            </div>
            <!--end:: State-->
          </div>
          <!--end:: Company Address-->
          <!--begin:: Supervisor Name, Title, Phone-->
          <div
            :id="'superVisorInfo-' + index"
            class="d-flex flex-column flex-md-row flex-wrap"
          >
            <!--begin:: Supervisor Name-->
            <div
              :id="'supervisorName-' + index"
              class="d-flex flex-column flex-fill m-3"
            >
              <label class="form-label fs-6 fw-bolder text-dark"
                >Supervisor Name</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                :name="'supervisorName-' + index"
                autocomplete="off"
                placeholder="name"
                v-model="formerEmployers[index].supervisorName"
              />
            </div>
            <!--end:: Supervisor Name-->
            <!--end:: Supervisor Title-->
            <div
              :id="'supervisorTitle-' + index"
              class="d-flex flex-column flex-fill m-3"
            >
              <label class="form-label fs-6 fw-bolder text-dark"
                >Supervisor Title</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                :name="'supervisorTitle-' + index"
                autocomplete="off"
                placeholder="title"
                v-model="formerEmployers[index].supervisorTitle"
              />
            </div>
            <!--end:: Supervisor Title-->
            <!--end:: Supervisor Phone-->
            <div
              :id="'supervisorPhone-' + index"
              class="d-flex flex-column flex-fill m-3"
            >
              <label class="form-label fs-6 fw-bolder text-dark"
                >Supervisor Phone</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                :name="'supervisorPhone-' + index"
                autocomplete="off"
                placeholder="phone"
                v-model="formerEmployers[index].phone"
              />
            </div>
            <!--end:: Supervisor Phone-->
          </div>
          <!--end:: Supervisor Name, Title, Phone-->
          <!--begin:: Salary-->
          <div
            :id="'salary-' + index"
            class="d-flex flex-column flex-md-row flex-wrap"
          >
            <!--begin:: Job Title-->
            <div
              :id="'jobTitle-' + index"
              class="d-flex flex-column flex-fill m-3"
            >
              <label class="form-label fs-6 fw-bolder text-dark"
                >Job Title</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                :name="'jobTitle-' + index"
                autocomplete="off"
                placeholder="job title"
                v-model="formerEmployers[index].position"
              />
            </div>
            <!--end:: Job Title-->
            <!--begin:: Starting Salary-->
            <div
              :id="'startingSalary-' + index"
              class="d-flex flex-column flex-fill m-3"
            >
              <label class="form-label fs-6 fw-bolder text-dark"
                >Starting Salary</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                :name="'startingSalary-' + index"
                autocomplete="off"
                placeholder="start salary"
                v-model="formerEmployers[index].salaryStart"
              />
            </div>
            <!--end:: Starting Salary-->
            <!--end:: Ending Salary-->
            <div
              :id="'endindSalary-' + index"
              class="d-flex flex-column flex-fill m-3"
            >
              <label class="form-label fs-6 fw-bolder text-dark"
                >Ending Salary</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                :name="'endindSalary-' + index"
                autocomplete="off"
                placeholder="end salary"
                v-model="formerEmployers[index].salaryEnd"
              />
            </div>
            <!--end:: Ending Salary-->
          </div>
          <!--end:: Salary-->
          <!--begin:: Major Responsibilities -->
          <div
            :id="'majorResponsibilities-' + index"
            class="d-flex flex-column flex-fill m-3"
          >
            <label
              class="d-flex align-items-center fs-6 fw-bolder text-dark mb-2"
            >
              <span>Major Responsibilities</span>
            </label>
            <el-input
              type="textarea"
              rows="2"
              :name="'majorResponsibilities-' + index"
              v-model="formerEmployers[index].majorResponsibilities"
            ></el-input>
          </div>
          <!--end:: Major Responsibilities -->
          <!--begin:: Reason For Leaving -->
          <div
            :id="'reasonForLeaving-' + index"
            class="d-flex flex-column flex-fill m-3"
          >
            <label
              class="d-flex align-items-center fs-6 fw-bolder text-dark mb-2"
            >
              <span>Reason for Leaving</span>
            </label>
            <el-input
              type="textarea"
              rows="2"
              :name="'reasonForLeaving-' + index"
              v-model="formerEmployers[index].reasonForLeaving"
            ></el-input>
          </div>
          <!--end:: Reason For Leaving -->
          <div class="separator my-2"></div>
          <!--end:: Former Employee -->
        </div>
        <!--end:: Former Employee Block -->
        <div class="d-flex m-3 justify-content-between">
          <!--begin::Add Former Employer-->
          <button
            v-if="showAddFormerEmployeeBtn"
            type="button"
            class="btn btn-primary"
            @click="addFormerEmployer()"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotune/arrows/arr075.svg" />
            </span>
            Enter Another Former Employer
          </button>
          <div v-if="!showAddFormerEmployeeBtn">
            <p class="text-danger bold">4 entries is the max</p>
          </div>
          <!--end::Add Former Employer-->
          <!--begin::Remove FormerEmployer-->
          <button
            v-if="showRemoveFormerEmployeeBtn"
            type="button"
            class="btn btn-danger"
            @click="removeFormerEmployer()"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotune/arrows/arr010.svg" />
            </span>
            Remove Former Employer
          </button>
          <!--end::Remove FormerEmployer-->
        </div>
      </div>

      <!--begin::Actions-->
      <div class="text-center">
        <!--begin::Submit button-->
        <button
          tabindex="3"
          type="submit"
          ref="submitButton"
          id="submitBtn"
          class="btn btn-lg btn-primary w-100 mb-5"
        >
          <span class="indicator-label"> Submit Application </span>

          <span class="indicator-progress">
            Please wait...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
        <!--end::Submit button-->
      </div>
      <div class="fv-plugins-message-container">
        <div class="d-flex flex-column fv-help-block">
          <ErrorMessage name="firstName" />
          <ErrorMessage name="lastName" />
          <ErrorMessage name="phone" />
          <ErrorMessage name="positionDesired" />
        </div>
      </div>
      <!--end::Actions-->
    </Form>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import {
  EmploymentApplication,
  Reference,
  Education,
  FormerEmployer,
} from "../../core/types/EmploymentTypes";

export default defineComponent({
  name: "apply",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const employmentApplication = ref({
      convictionExplanation: "",
      otherEducation: "",
      relevantTraining: "",
    } as EmploymentApplication);
    const references = ref([] as Reference[]);
    const educations = ref([] as Education[]);
    const formerEmployers = ref([] as FormerEmployer[]);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const usStates = computed(() => store.state.usStates);
    //Create form validation object
    const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    const validation = Yup.object().shape({
      firstName: Yup.string().required().label("FirstName"),
      lastName: Yup.string().required().label("LastName"),
      phone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required()
        .label("Phone"),
      positionDesired: Yup.string().required().label("Position Desired"),
    });
    const showOtherReferral = ref(false);
    const showAddEducationBtn = ref(true);
    const showRemoveEducationBtn = ref(true);
    const showAddFormerEmployeeBtn = ref(true);
    const showRemoveFormerEmployeeBtn = ref(true);

    //Form submit function
    const onSubmitApplication = () => {
      // Send Application
      employmentApplication.value.educations = educations.value;
      employmentApplication.value.references = references.value;
      employmentApplication.value.formerEmployers = formerEmployers.value;

      store
        .dispatch(
          Actions.POST_EMPLOYMENT_APPLICATION,
          employmentApplication.value
        )
        .then(() => {
          router.push({ name: "applySuccess" });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Okay.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        });

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
    };

    onMounted(() => {
      educations.value.push({
        id: 0,
        educationLevel: "",
        name: "",
        location: "",
        courseOfStudy: "",
        yearsCompleted: "",
        degreeDiploma: "",
      });

      var initEmployers = [{} as FormerEmployer];
      formerEmployers.value.push(...initEmployers);

      var initReferences = [{} as Reference, {} as Reference, {} as Reference];
      references.value.push(...initReferences);
    });

    function addEducation() {
      educations.value.push({
        id: 0,
        educationLevel: "",
        name: "",
        location: "",
        courseOfStudy: "",
        yearsCompleted: "",
        degreeDiploma: "",
      });
    }

    function removeEducation() {
      educations.value.pop();
    }

    function addFormerEmployer() {
      formerEmployers.value.push({
        id: 0,
        companyName: "",
        address: "",
        city: "",
        state: "",
        fromDate: "",
        toDate: "",
        supervisorName: "",
        supervisorTitle: "",
        phone: "",
        position: "",
        salaryStart: "",
        salaryEnd: "",
        majorResponsibilities: "",
        reasonForLeaving: "",
      });
    }

    function removeFormerEmployer() {
      formerEmployers.value.pop();
    }

    function convictionExplanationKeyDown(evt) {
      if (employmentApplication.value.convictionExplanation.length >= 200) {
        if (evt.keyCode >= 24 && evt.keyCode <= 186) {
          evt.preventDefault();
        }
      }
    }

    function otherEducationKeyDown(evt) {
      if (employmentApplication.value.otherEducation.length >= 430) {
        if (evt.keyCode >= 24 && evt.keyCode <= 186) {
          evt.preventDefault();
        }
      }
    }

    function relevantTrainingKeyDown(evt) {
      if (employmentApplication.value.relevantTraining.length >= 210) {
        if (evt.keyCode >= 24 && evt.keyCode <= 186) {
          evt.preventDefault();
        }
      }
    }

    watch(employmentApplication.value, () => {
      if (employmentApplication.value.snappyReferral === "Other") {
        showOtherReferral.value = true;
      } else {
        showOtherReferral.value = false;
      }
    });

    watch(educations.value, () => {
      if (educations.value.length > 3) {
        showAddEducationBtn.value = false;
      } else {
        showAddEducationBtn.value = true;
      }
      if (educations.value.length === 1) {
        showRemoveEducationBtn.value = false;
      } else {
        showRemoveEducationBtn.value = true;
      }
    });

    watch(formerEmployers.value, () => {
      if (formerEmployers.value.length > 3) {
        showAddFormerEmployeeBtn.value = false;
      } else {
        showAddFormerEmployeeBtn.value = true;
      }
      if (formerEmployers.value.length === 1) {
        showRemoveFormerEmployeeBtn.value = false;
      } else {
        showRemoveFormerEmployeeBtn.value = true;
      }
    });

    return {
      employmentApplication,
      references,
      educations,
      formerEmployers,
      usStates,
      onSubmitApplication,
      phoneRegExp,
      validation,
      showOtherReferral,
      submitButton,
      addEducation,
      removeEducation,
      showAddEducationBtn,
      showRemoveEducationBtn,
      addFormerEmployer,
      removeFormerEmployer,
      showAddFormerEmployeeBtn,
      showRemoveFormerEmployeeBtn,
      convictionExplanationKeyDown,
      otherEducationKeyDown,
      relevantTrainingKeyDown,
    };
  },
});
</script>

<style lang="scss">
/* Responsive Code */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768 and up)*/
@media (min-width: 768px) {
}

/* Large devices (desktops, 992 and up)*/
@media (min-width: 992px) {
  .w-lg-45 {
    width: 45% !important;
  }
}

/* Extra large devices (large desktops, 1200 and up)*/
@media (min-width: 1200px) {
}
</style>
