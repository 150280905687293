
import { defineComponent, ref, computed, watch, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import {
  EmploymentApplication,
  Reference,
  Education,
  FormerEmployer,
} from "../../core/types/EmploymentTypes";

export default defineComponent({
  name: "apply",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const employmentApplication = ref({
      convictionExplanation: "",
      otherEducation: "",
      relevantTraining: "",
    } as EmploymentApplication);
    const references = ref([] as Reference[]);
    const educations = ref([] as Education[]);
    const formerEmployers = ref([] as FormerEmployer[]);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const usStates = computed(() => store.state.usStates);
    //Create form validation object
    const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    const validation = Yup.object().shape({
      firstName: Yup.string().required().label("FirstName"),
      lastName: Yup.string().required().label("LastName"),
      phone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required()
        .label("Phone"),
      positionDesired: Yup.string().required().label("Position Desired"),
    });
    const showOtherReferral = ref(false);
    const showAddEducationBtn = ref(true);
    const showRemoveEducationBtn = ref(true);
    const showAddFormerEmployeeBtn = ref(true);
    const showRemoveFormerEmployeeBtn = ref(true);

    //Form submit function
    const onSubmitApplication = () => {
      // Send Application
      employmentApplication.value.educations = educations.value;
      employmentApplication.value.references = references.value;
      employmentApplication.value.formerEmployers = formerEmployers.value;

      store
        .dispatch(
          Actions.POST_EMPLOYMENT_APPLICATION,
          employmentApplication.value
        )
        .then(() => {
          router.push({ name: "applySuccess" });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Okay.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        });

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
    };

    onMounted(() => {
      educations.value.push({
        id: 0,
        educationLevel: "",
        name: "",
        location: "",
        courseOfStudy: "",
        yearsCompleted: "",
        degreeDiploma: "",
      });

      var initEmployers = [{} as FormerEmployer];
      formerEmployers.value.push(...initEmployers);

      var initReferences = [{} as Reference, {} as Reference, {} as Reference];
      references.value.push(...initReferences);
    });

    function addEducation() {
      educations.value.push({
        id: 0,
        educationLevel: "",
        name: "",
        location: "",
        courseOfStudy: "",
        yearsCompleted: "",
        degreeDiploma: "",
      });
    }

    function removeEducation() {
      educations.value.pop();
    }

    function addFormerEmployer() {
      formerEmployers.value.push({
        id: 0,
        companyName: "",
        address: "",
        city: "",
        state: "",
        fromDate: "",
        toDate: "",
        supervisorName: "",
        supervisorTitle: "",
        phone: "",
        position: "",
        salaryStart: "",
        salaryEnd: "",
        majorResponsibilities: "",
        reasonForLeaving: "",
      });
    }

    function removeFormerEmployer() {
      formerEmployers.value.pop();
    }

    function convictionExplanationKeyDown(evt) {
      if (employmentApplication.value.convictionExplanation.length >= 200) {
        if (evt.keyCode >= 24 && evt.keyCode <= 186) {
          evt.preventDefault();
        }
      }
    }

    function otherEducationKeyDown(evt) {
      if (employmentApplication.value.otherEducation.length >= 430) {
        if (evt.keyCode >= 24 && evt.keyCode <= 186) {
          evt.preventDefault();
        }
      }
    }

    function relevantTrainingKeyDown(evt) {
      if (employmentApplication.value.relevantTraining.length >= 210) {
        if (evt.keyCode >= 24 && evt.keyCode <= 186) {
          evt.preventDefault();
        }
      }
    }

    watch(employmentApplication.value, () => {
      if (employmentApplication.value.snappyReferral === "Other") {
        showOtherReferral.value = true;
      } else {
        showOtherReferral.value = false;
      }
    });

    watch(educations.value, () => {
      if (educations.value.length > 3) {
        showAddEducationBtn.value = false;
      } else {
        showAddEducationBtn.value = true;
      }
      if (educations.value.length === 1) {
        showRemoveEducationBtn.value = false;
      } else {
        showRemoveEducationBtn.value = true;
      }
    });

    watch(formerEmployers.value, () => {
      if (formerEmployers.value.length > 3) {
        showAddFormerEmployeeBtn.value = false;
      } else {
        showAddFormerEmployeeBtn.value = true;
      }
      if (formerEmployers.value.length === 1) {
        showRemoveFormerEmployeeBtn.value = false;
      } else {
        showRemoveFormerEmployeeBtn.value = true;
      }
    });

    return {
      employmentApplication,
      references,
      educations,
      formerEmployers,
      usStates,
      onSubmitApplication,
      phoneRegExp,
      validation,
      showOtherReferral,
      submitButton,
      addEducation,
      removeEducation,
      showAddEducationBtn,
      showRemoveEducationBtn,
      addFormerEmployer,
      removeFormerEmployer,
      showAddFormerEmployeeBtn,
      showRemoveFormerEmployeeBtn,
      convictionExplanationKeyDown,
      otherEducationKeyDown,
      relevantTrainingKeyDown,
    };
  },
});
